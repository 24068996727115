import React, {useState} from "react";
import {SelectBox} from "../components/select.box";
import Datepicker from "react-tailwindcss-datepicker";
import {CheckCircleIcon} from "@heroicons/react/20/solid";
import {AxiosInstance} from "../services/http";
import {useToast} from "../contexts/ToastContext";
import {styles} from "../utils/styles";
import {types} from "../utils/types";
import {getCategories} from "../utils/categories";

export const ResourcesBulkUpload = (props) => {
    const { resource } = props;
    const [isUploading, setIsUploading] = useState(false);
    const [payload, setPayload] = useState({
        is_free : {
            id: '',
            name: 'Select Free or Paid',
        },
        style: {
            id: 'undefined',
            name: 'Select Style'
        },
        type: {
            id: 'undefined',
            name: 'Select Type'
        },
        category: {
            id: 'undefined',
            name: 'Select Category'
        },
        publish_date: {
            startDate: '',
            endDate: ''
        },
        zip_file: undefined
    });
    const categories = getCategories();
    const [showFileMessage, setShowFileMessage] = useState(false);
    const toast = useToast();
    const upload = () => {
        const formData = new FormData();
        formData.append('is_free', payload.is_free.id);
        formData.append('publish_date', payload.publish_date.startDate);
        formData.append('resource', resource);
        if (payload.category.id !== 'undefined') {
            formData.append('category_id', payload.category.id);
        }
        if (payload.style.id !== 'undefined') {
            formData.append('style_id', payload.style.id);
        }
        if (payload.type.id !== 'undefined') {
            formData.append('type_id', payload.type.id);
        }
        formData.append('zip_file', payload.zip_file, 'zip_file.zip');
        setIsUploading(true);
        let uri = '/api/v1/upload/bulk';
        AxiosInstance('POST', uri,{}, formData)
            .then(res => {
            if (res.status === 200){
                toast('success', 'Success!', 'File successfully added to the upload Queue!');
            }else{
                if (res.response.data.error) {
                    toast('error', 'Error!', res.response.data.error[0]);
                }else {
                    toast('error', 'Error!', 'Error uploading zip file!');
                }
            }
            setIsUploading(false);
        });
    }

    return (
        <div className="space-y-10 divide-y divide-gray-900/10">
            <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-6">
                <div className="px-4 sm:px-0  md:col-span-1">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                        Bulk Upload {resource.toUpperCase()}
                    </h2>
                </div>

                <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-6">
                    <div className="px-4 py-6 sm:p-8">
                        <div className="grid grid-cols-6 gap-x-6 gap-y-8 sm:grid-cols-12">
                            <div className="sm:col-span-2">
                                <SelectBox label="Is Free" items={[
                                    {
                                        id: '',
                                        name: 'Select Free or Paid',
                                    },
                                    {
                                        id: '1',
                                        name: 'Free',
                                    },
                                    {
                                        id: '0',
                                        name: 'Paid',
                                    },
                                ]} selected={payload.is_free}
                                           setSelected={(v) => {
                                               setPayload({
                                                   ...payload,
                                                   is_free: v
                                               })
                                           }}/>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="verified-at"
                                       className="block text-sm font-medium leading-6 text-gray-900">
                                    Publish Date
                                </label>
                                <div className="mt-2">
                                    <Datepicker
                                        useRange={false}
                                        asSingle={true}
                                        primaryColor={"indigo"}
                                        value={payload.publish_date}
                                        inputClassName="w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        onChange={(v) => {
                                            setPayload({...payload, publish_date: v});
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <SelectBox label="Style" items={styles[resource]} selected={payload.style}
                                           setSelected={(v) => {
                                               setPayload({
                                                   ...payload,
                                                   style: v,
                                               })
                                           }}/>
                            </div>
                            <div className="sm:col-span-2">
                                <SelectBox label="Type" items={types[resource]} selected={payload.type}
                                           setSelected={(v) => {
                                               setPayload({
                                                   ...payload,
                                                   type: v
                                               })
                                           }}/>
                            </div>
                            <div className="sm:col-span-2">
                                <SelectBox label="Category" items={categories} selected={payload.category}
                                           setSelected={(v) => {
                                               setPayload({
                                                   ...payload,
                                                   category: v
                                               })
                                           }}/>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="first-name"
                                       className="block text-sm font-medium leading-6 text-gray-900">
                                    Actions
                                </label>
                                <div className="mt-2">
                                    <span className="mr-2">
                                    <label
                                        htmlFor='file-upload'
                                        className="rounded-md bg-indigo-600 px-3 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                    Select zip File
                                    </label>
                                    <input hidden={true} id="file-upload" name="file-upload" type="file"
                                           className="cursor-pointer sr-only" onChange={(e) => {
                                        setPayload({...payload, zip_file: e.target.files[0]})
                                        setShowFileMessage(true);
                                    }}/>
                                </span>
                                    <button
                                        disabled={isUploading}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            upload();
                                        }}
                                        type="submit"
                                        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        {isUploading ? 'Please Wait' : 'Upload'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        showFileMessage &&
                        <div className="rounded bg-green-50 p-4 m-2">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <CheckCircleIcon className="h-5 w-5 text-green-400"
                                                     aria-hidden="true"/>
                                </div>
                                <div className="ml-3">
                                    <h3 className="text-sm font-medium text-green-800">File
                                        Name: {payload.zip_file.name}</h3>
                                    <div className="mt-2 text-sm text-green-700">
                                        <p>Just click on upload button and wait for it to be uploaded!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </form>
            </div>
        </div>
    )
}
