import axios from 'axios';

const MAIN_URL = process.env.REACT_APP_MAIN_URL;
export const AxiosInstance = (method, uri,params, data, headers, controller) => {
    return axios({
        method: method,
        url: MAIN_URL + uri,
        data: data,
        params: params,
        headers: {
            ...headers,
            Authorization: localStorage.getItem('auth_token')
        },
        signal: controller ? controller.signal : (new AbortController()).signal
    }).catch(e => {

        console.log(e);
        if (!e.response && e.message !== 'canceled'){
            //window.location.replace('/error');
            //return;
        }

        if (e.response.status){
            if (e.response.status === 401){
                localStorage.removeItem('auth_token');
                localStorage.removeItem('user');
                if (window.location.pathname !== '/login') {
                    window.location.replace('/login');
                }
            }else {
                return e;
            }
        }
    })
}
