import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {AxiosInstance} from "../services/http";
import {useToast} from "../contexts/ToastContext";
import {Loading} from "../components/loading";
import {NotFound} from "./not.found";
import {SelectBox} from "../components/select.box";
import Datepicker from "react-tailwindcss-datepicker";

export const Notification = () => {
    const {notification_id} = useParams();
    const toast = useToast();
    const [notification, setNotification] = useState(undefined);
    const [fetching, setFetching] = useState(false);
    const [isUpdatingNotification, setIsUpdatingNotification] = useState(false);
    const types = [
        {
            "id": undefined,
            "name": "Select Type"
        },
        {
            "id": 'simple',
            "name": "Simple"
        },
        {
            "id": 'floating',
            "name": "Floating"
        }
    ];
    const actions = [
        {
            "id": null,
            "name": "Select Action"
        },
        {
            "id": 'link',
            "name": "Link"
        }
    ];
    const getNotification = () => {
        AxiosInstance('GET', '/api/v1/notifications/' + notification_id)
            .then(res => {
                if (res.status === 200) {
                    const response = res.data.resulting_data;
                    setNotification(
                        {
                            type: types.filter((item) => item.id === response.type)[0],
                            action: actions.filter((item) => item.id === response.action)[0],
                            title: response.title,
                            data: response.data,
                            action_text: response.action_text,
                            action_value: response.action_value,
                            expires_at: {
                                startDate: response.expires_at.replace(' 00:00:00',''),
                                endDate: ''
                            }
                        }
                    );
                    console.log(notification);
                } else {
                    setNotification(null);
                    toast('error', 'Error!', 'Error fetching category!');
                }
                setFetching(false);
            })
    }

    const updateNotification = () => {
        setIsUpdatingNotification(true);
        const uri = notification_id ? `/api/v1/notifications/${notification_id}` : '/api/v1/notifications/';
        AxiosInstance(notification_id ? 'PUT' : 'POST', uri,{},{
            type: notification.type.id,
            action: notification.action.id,
            action_text: notification.action_text,
            action_value: notification.action_value,
            title: notification.title,
            data: notification.data,
            expires_at: notification.expires_at.startDate
        })
            .then(res => {
                if (res.status === 200){
                    const response = res.data.resulting_data;
                    if (!notification_id){
                        window.location.replace(`/notifications/${response.id}`);
                    }
                    setNotification(
                        {
                            type: types.filter((item) => item.id === response.type)[0],
                            title: response.title,
                            data: response.data,
                            action: response.action,
                            action_text: response.action_text,
                            action_value: response.action_value,
                            expires_at: {
                                startDate: response.expires_at.replace(' 00:00:00',''),
                                endDate: ''
                            }
                        }
                    );
                    toast('success', 'Success!', 'Notification added/updated successfully!');
                }else{
                    toast('error', 'Error!', 'Error adding/updating notification!');
                }
                setIsUpdatingNotification(false);
            });
    }
    useEffect(() => {
        if (notification_id) {
            setFetching(true);
            getNotification(notification_id);
        } else {
            setNotification({
                type: {
                    "id": undefined,
                    "name": "Select Type"
                },
                action: {
                    "id": null,
                    "name": "Select Action"
                },
                title: '',
                action_text: null,
                action_value: null,
                data: '',
                expires_at: {
                    startDate: '',
                    endDate: ''
                },
            })
        }
    }, [notification_id]);
    return (
        fetching ? <Loading/> : (notification ? (
                    <div className="space-y-10 divide-y divide-gray-900/10">
                        <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-6">
                            <div className="px-4 sm:px-0  md:col-span-1">
                                <h2 className="text-base font-semibold leading-7 text-gray-900">
                                    Notification Details
                                </h2>
                            </div>
                            <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-6">
                                <div className="px-4 py-6 sm:p-8">
                                    <div className="grid grid-cols-6 gap-x-6 gap-y-8 sm:grid-cols-12">
                                        <div className="sm:col-span-2">
                                            <SelectBox label="Type" items={types}
                                                       selected={notification.type}
                                                       setSelected={(v) => {
                                                           setNotification({
                                                               ...notification,
                                                               type: v
                                                           })
                                                       }}/>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <label htmlFor="title"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Title
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    name="title"
                                                    id="title"
                                                    value={notification?.title}
                                                    onChange={(e) => setNotification({
                                                        ...notification,
                                                        title: e.target.value
                                                    })}
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <label htmlFor="verified-at"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Expires At {` ( ${notification?.expires_at?.startDate} ) `}
                                            </label>
                                            <div className="mt-2">
                                                <Datepicker
                                                    useRange={false}
                                                    asSingle={true}
                                                    primaryColor={"indigo"}
                                                    value={notification.expires_at}
                                                    inputClassName="w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    onChange={(v) => {
                                                        setNotification({...notification, expires_at: v});
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <SelectBox label="Action" items={actions}
                                                       selected={notification.action}
                                                       setSelected={(v) => {
                                                           setNotification({
                                                               ...notification,
                                                               action: v
                                                           })
                                                       }}/>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <label htmlFor="title"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Action Text
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    name="action_text"
                                                    id="action_text"
                                                    value={notification?.action_text}
                                                    onChange={(e) => setNotification({
                                                        ...notification,
                                                        action_text: e.target.value
                                                    })}
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <label htmlFor="title"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Action Value
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    name="action_value"
                                                    id="action_value"
                                                    value={notification?.action_value}
                                                    onChange={(e) => setNotification({
                                                        ...notification,
                                                        action_value: e.target.value
                                                    })}
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-11">
                                            <label htmlFor="data"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Description
                                            </label>
                                            <div className="mt-2">
                                                <textarea
                                                    name="data"
                                                    id="data"
                                                    value={notification?.data}
                                                    onChange={(e) => setNotification({
                                                        ...notification,
                                                        data: e.target.value
                                                    })}
                                                    rows={4}
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <label htmlFor="actions"
                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                Actions
                                            </label>
                                            <div className="mt-2">
                                                <button
                                                    disabled={isUpdatingNotification}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        updateNotification();
                                                    }}
                                                    type="submit"
                                                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                >
                                                    {isUpdatingNotification ? 'Please Wait' : (notification_id ? 'Update' : 'Create')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                ) :
                <NotFound/>
        )
    )
}